import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { AutocompleteDataSource, AutocompleteItem } from './autocomplete-data-source';
import { InputValueDebouncer } from '@util/classes/value-debouncer.class';
import { distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { coerceBoolean } from '@util/functions/objects';
import { TranslationService, WindowRef } from '@spartacus/core';
import { FocusGroupCreationResult, createFocusGroup } from '@util/functions/events';
import { AUTOCOMPLETE_ITEM_CELLULOID_COMPONENT_REF_INJECTION_TOKEN as AUTOCOMPLETE_ITEM_CELLULOID_CMP_REF_INJECTION_TOKEN, AUTOCOMPLETE_ITEM_CELLULOID_EXTRA_DATA_INJECTION_TOKEN, AUTOCOMPLETE_ITEM_CELLULOID_ITEM_DATA_INJECTION_TOKEN, AutocompleteItemCelluloidComponentData } from './autocomplete.types';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { UtilOmnipresentFormGroupService } from '@util/services/util-omnipresent-form-group.service';
import { StandaloneValidator } from '@util/classes/standalone-validator.class';
import { AccessabilityComponentObject } from '@util/types/shared.types';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {

  private static _uniqueIdCounter = 0;
  protected uid = 'autocomplete-component-' + (AutocompleteComponent._uniqueIdCounter++);
  protected listboxId = 'listbox-' + this.uid;

  /**
   * handles item selection with the keyboard arrows and enter key as well as closing the menu with the escape key
   */
  @HostListener('keydown', ['$event'])
  private pressedDown(e: KeyboardEvent) {

    const killEvent = (e: Event, andPrevent = false) => {
      e.preventDefault();
      e.stopPropagation();

      if (andPrevent) {
        const keyUpFn = (e: KeyboardEvent) => {
          e.preventDefault();
          (e.target as HTMLElement).removeEventListener('keyup', keyUpFn);
        };
        (e.target as HTMLElement).addEventListener('keyup', keyUpFn);
      }
    };

    if (this.windowRef.nativeWindow.document.activeElement.nodeName === 'INPUT') {
      if (e.code === 'ArrowUp' && this.dataSource) {
        const nowItem = this.dataSource.focusUp();
        if (nowItem) {
          this.scrollToFocusedItem();
          killEvent(e, true);
        }

      }
      if (e.code === 'ArrowDown' && this.dataSource) {
        this.openMenu();
        const nowItem = this.dataSource.focusDown();
        if (nowItem) {
          this.scrollToFocusedItem();
          killEvent(e, true);
        }

      }
      if (e.code === 'Enter' && this.dataSource) {
        const nowItem = this.dataSource.getFocusedItem();
        if (nowItem) {
          this.setText(nowItem.label);
          this.closeMenu();
          killEvent(e);
        }

      }
    }

    // HANDLE ESCAPE
    if (e.code === 'Escape') {
      if (this.open$.value) {
        this.closeMenu();
        killEvent(e);
      }
    }
  }

  protected elementRef = inject(ElementRef);
  protected windowRef = inject(WindowRef);
  protected injector = inject(Injector);
  protected utilCustomCSSPropertyService = inject(UtilCustomCSSPropertyService);
  protected utilOmnipresentFormGroupService = inject(UtilOmnipresentFormGroupService);
  protected translationService = inject(TranslationService);

  protected dataSourceBehaviorSubject = new BehaviorSubject<AutocompleteDataSource>(null);
  protected disabledBehaviorSubject = new BehaviorSubject<boolean>(false);
  protected labellessBehaviorSubject = new BehaviorSubject<boolean>(false);

  protected _requiredBehaviorSubject = new BehaviorSubject<boolean>(false);
  protected _formGroupNameBehaviorSubject = new BehaviorSubject<string>('');
  protected _checksRegistrationOfPseudoValidatorInFormGroupServiceSubscription: Subscription;

  protected _completeDataSourcesOnEndOfUse = false;

  protected celluloidDataBehaviorSubject = new BehaviorSubject<AutocompleteItemCelluloidComponentData>(null);

  protected commandEmitterSubscription: Subscription;
  protected _showInfoIcon: boolean;

  protected __inputDebouncer = new InputValueDebouncer('', String);
  protected inputFocusTrigger = new Subject<boolean>();

  protected res: FocusGroupCreationResult;

  open$ = new BehaviorSubject<boolean>(false);

  protected acoSub: Subscription;
  aco$ = new BehaviorSubject<AccessabilityComponentObject>(null);

  get dataSource$(): Observable<AutocompleteDataSource> {
    return this.dataSourceBehaviorSubject.asObservable();
  }

  get dataSource(): AutocompleteDataSource {
    return this.dataSourceBehaviorSubject.value;
  }

  @Input()
  set completeDataSourcesOnEndOfUse(value: boolean) {
    this._completeDataSourcesOnEndOfUse = coerceBoolean(value);
  }

  get completeDataSourcesOnEndOfUse(): boolean {
    return this._completeDataSourcesOnEndOfUse;
  }

  @Input()
  set dataSource(value: AutocompleteDataSource) {

    this.__inputDebouncer.unsubscribe();
    this.commandEmitterSubscription?.unsubscribe();

    this.dataSourceBehaviorSubject.value?.complete();

    if (value) {
      value.init();
      // if the text of the component is not the same as the text of the dataSource ...
      if (this.__inputDebouncer.value !== value.userInput$.value) {
        // ... then set the text of the component so that it has the same text as the dataSource
        this.__inputDebouncer.setValue(value.userInput$.value);
      }
      // subscribe to changes of the text of the component so that the text of the dataSource is always the same
      this.__inputDebouncer.subscription = this.__inputDebouncer.value$.pipe(distinctUntilChanged(), skip(1)).subscribe(input => {
        value.userInput$.next(input);
      });

      // listenings for commands
      this.commandEmitterSubscription = value.commandEmitter.pipe(skip(1)).subscribe(message => {
        if (typeof message === 'string') {
          const iColon = message.indexOf(':');
          const cmd = message.slice(0, iColon);
          const arg = message.slice(iColon + 1);
          this.processCommands(cmd, arg);
        }
      });

      // making sure that the pre selected item of the datasource
      // is displayed in the component's input
      const currentLabel = value.selectedItem?.label || '';
      this.__inputDebouncer.setValue(currentLabel);
    }

    if (this.dataSourceBehaviorSubject.value && this.dataSourceBehaviorSubject.value !== value) {
      this.dataSourceBehaviorSubject.value.onremove$.next(this);
    }

    this.dataSourceBehaviorSubject.next(value);
  }

  @Input()
  placeholder: string;

  @Input()
  label: string;

  get labelless(): boolean {
    return this.labellessBehaviorSubject.value;
  }

  @Input()
  set labelless(value: boolean) {
    this.labellessBehaviorSubject.next(coerceBoolean(value));
  }

  get disabled(): boolean {
    return this.disabledBehaviorSubject.value;
  }

  @Input()
  set disabled(value: boolean) {
    this.disabledBehaviorSubject.next(coerceBoolean(value));
  }

  get required(): boolean {
    return this._requiredBehaviorSubject.value;
  }

  @Input()
  set required(value: boolean) {
    this._requiredBehaviorSubject.next(coerceBoolean(value));
  }

  get formGroupName(): string {
    return this._formGroupNameBehaviorSubject.value;
  }

  @Input()
  set formGroupName(value: string) {
    this._formGroupNameBehaviorSubject.next(value);
  }

  get showInfoIcon(): boolean {
    return this._showInfoIcon;
  }

  @Input()
  set showInfoIcon(value: boolean) {
    this._showInfoIcon = coerceBoolean(value);
  }

  get celluloidData$(): Observable<AutocompleteItemCelluloidComponentData> {
    return this.celluloidDataBehaviorSubject.asObservable();
  }

  get celluloidData(): AutocompleteItemCelluloidComponentData {
    return this.celluloidDataBehaviorSubject.value;
  }

  @Input()
  set celluloidData(value: AutocompleteItemCelluloidComponentData) {
    if (value) {
      value.__injector ||= Injector.create({
        name: 'autocompleteItemCelluloidComponent',
        providers: [
          {
            provide: AUTOCOMPLETE_ITEM_CELLULOID_EXTRA_DATA_INJECTION_TOKEN,
            useValue: value.data
          },
          {
            provide: AUTOCOMPLETE_ITEM_CELLULOID_CMP_REF_INJECTION_TOKEN,
            useValue: this
          },
        ],
        parent: this.injector
      });
    }
    this.celluloidDataBehaviorSubject.next(value);
  }

  @Input()
  narrow: boolean;

  @Output()
  readonly info = new EventEmitter<UIEvent>();

  @Output()
  readonly focus = new EventEmitter<FocusEvent>();

  @Output()
  readonly blur = new EventEmitter<FocusEvent>();

  ngOnInit() {
    let oldFormGroupName: string;

    // adds the requiredPseudoValidator to the given formGroupName if it is required
    this._checksRegistrationOfPseudoValidatorInFormGroupServiceSubscription =
    combineLatest([
      this._requiredBehaviorSubject,
      this._formGroupNameBehaviorSubject
    ]).subscribe(([isRequired, name]) => {

      const oldHasIt = this.utilOmnipresentFormGroupService.hasValidator(oldFormGroupName, this.requiredStandaloneValidator);
      const hasIt = this.utilOmnipresentFormGroupService.hasValidator(name, this.requiredStandaloneValidator);

      // if old name has the validator, then remove it from old name
      if (oldFormGroupName !== name && oldHasIt) {
        this.utilOmnipresentFormGroupService.unregisterValidator(oldFormGroupName, this.requiredStandaloneValidator);
      }

      // if it is not required but the new name has the validator, then remove it from new name
      if (!isRequired && name && hasIt) {
        this.utilOmnipresentFormGroupService.unregisterValidator(name, this.requiredStandaloneValidator);
      }

      // if it is required but the new name does not have the validator, then register it for new name
      if (isRequired && name && !hasIt) {
        this.utilOmnipresentFormGroupService.registerValidator(name, this.requiredStandaloneValidator);
      }

      oldFormGroupName = name;

      this.acoSub = combineLatest([
        this.open$,
        this.dataSource$.pipe(filter(ds => !!ds), switchMap(ds => ds.focusedItem$)),
      ]).subscribe(([isOpen, focusedItem]) => {

        const adId = focusedItem ? (this.listboxId + '_' + focusedItem.value) : '';

        this.aco$.next({
          role: 'combobox',
          ariaControls: this.listboxId,
          ariaExpanded: isOpen ? 'true' : 'false',
          ariaHaspopup: 'listbox',
          ariaActivedescendant: isOpen ? adId : ''
        });

      });

    });



  }

  ngAfterViewInit(): void {
    const host = this.elementRef.nativeElement as HTMLElement;

    this.res = createFocusGroup(host);
    this.res.focusGroupOut$.subscribe(e => {
      this.onBlur(e.focusEvent);
    });
  }

  ngOnDestroy(): void {
    this.__inputDebouncer.unsubscribe();
    this.commandEmitterSubscription?.unsubscribe();
    this.res.complete();

    if (this.completeDataSourcesOnEndOfUse && this.dataSourceBehaviorSubject.value) {
      this.dataSourceBehaviorSubject.value.complete();
    }

    if (this.dataSourceBehaviorSubject.value) {
      this.dataSourceBehaviorSubject.value.onremove$.next(this);
    }

    this._checksRegistrationOfPseudoValidatorInFormGroupServiceSubscription?.unsubscribe();
    this.acoSub?.unsubscribe();
  }

  openMenu() {
    if (!this.open$.value) {
      this.open$.next(true);
      this.calculateAndSetMaxMenuHeight();
      this.calculateAndSetInputHeight();
    }
  }

  closeMenu() {
    if (this.open$.value) {
      this.open$.next(false);
    }
    // call removeItemFocus() after the main cycle
    // so that the "pressedDown" event handle still knows which item is focused
    this.windowRef.nativeWindow.setTimeout(() => {
      this.dataSource?.removeItemFocus();
    }, 0);
  }

  toggleMenu() {
    if (this.open$.value) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  deleteText() {
    this.setText('');
    this.inputFocusTrigger.next(true);
  }

  setText(text: string) {
    const input = this.getInputElement();
    if (input) {
      input.value = text;
    }
    this.__inputDebouncer.setValue(text);
  }

  getText(): string {
    return this.__inputDebouncer.getValue();
  }

  onFocus(e: FocusEvent) {
    this.focus.emit(e);
    this.openMenu();
  }

  onBlur(e: FocusEvent) {

    if (this.dataSource.options.strict && !this.dataSource.getSelectedItem()) {
      this.setText('');
    }
    // to make sure that closeMenu() is not called before click-event on item (clickItem())
    setTimeout(() => {
      this.closeMenu();
    }, 0);

    this.blur.emit(e);
    this.dataSource?.options.onElementBlur?.(e, this.dataSource?.selectedItem);

  }

  inputTextHandler(e: InputEvent) {
    this.openMenu();
  }

  clickItem(item: AutocompleteItem, e: PointerEvent) {
    this.setText(item.label);

    this.closeMenu();
  }

  clickInfo(e: UIEvent) {
    this.info.emit(e);
  }

  private getInputElement() {
    const el = (this.elementRef.nativeElement as HTMLElement)?.querySelector('input');
    return el;
  }

  private scrollToFocusedItem() {
    // setTimeout here so that scrollToFocusedItem() can be called in the same process cycle which changes the focused item switch
    setTimeout(() => {

      const host = this.elementRef.nativeElement as HTMLElement;
      const focusedEl = host.querySelector('.item-focused');

      if (focusedEl) {
        focusedEl.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
      }

    }, 0);
  }

  private processCommands(cmd: string, arg: string) {

    if (cmd === 'closeMenu' && arg === 'true') {
      this.closeMenu();
    }

    if (cmd === 'openMenu' && arg === 'true') {
      this.openMenu();
    }

    if (cmd === 'write' && typeof arg === 'string') {
      this.setText(arg);
    }

    if (cmd === 'saveWrite' && typeof arg === 'string') {
      if (this.getText() !== arg) {
        this.setText(arg);
      }
    }

    if (cmd === 'focus' && arg === 'true') {
      this.inputFocusTrigger.next(true);
    }

  }

  getInjectorForThisItem(item: AutocompleteItem) {

    if (item && !item['__itemInjector']) {
      item['__itemInjector'] = Injector.create({
        name: 'localAutocompleteItem',
        providers: [
          {
            provide: AUTOCOMPLETE_ITEM_CELLULOID_ITEM_DATA_INJECTION_TOKEN,
            useValue: item
          },
        ],
        parent: this.celluloidDataBehaviorSubject.value.__injector
      });
    }

    return item['__itemInjector'];

  }

  /**
   * it does not need to be pixel perfect
   */
  private calculateAndSetMaxMenuHeight(ds?: AutocompleteDataSource) {

    ds ||= this.dataSource;

    const maxNum = ds.options.maxVisibleItems || 5;

    const host = this.elementRef.nativeElement as HTMLElement;

    // thats the quick value
    let itemHeight = 37.59;

    const itemEl = host.querySelector('.app-autocomplete-item');
    if (itemEl) {
      itemHeight = itemEl.getBoundingClientRect().height;
    }

    const maxHeight = itemHeight * maxNum;

    this.utilCustomCSSPropertyService.setValue('--_autocomplete-items-container-max-height', maxHeight + 'px', host);

  }

  /**
   * it needs to be pixel perfect
   */
  private calculateAndSetInputHeight() {

    const host = this.elementRef.nativeElement as HTMLElement;

    // thats the quick value
    let inputHeight = 61;

    const appInputEl = host.querySelector('app-input');
    if (appInputEl) {
      inputHeight = appInputEl.getBoundingClientRect().height;
    }

    // decrease by 1 pixel to cause a slight overlap of the menu
    inputHeight -= 1;

    this.utilCustomCSSPropertyService.setValue('--_app-input-height', inputHeight + 'px', host);

  }

  private requiredStandaloneValidator = new StandaloneValidator(
    _ => {
      return this.required ? !!(_?.value) : true;
    },
    {
      updateObservable: this.dataSource$.pipe(
        filter(_ => !!_),
        switchMap(ds => {
          return combineLatest([
            ds.selectedItem$,
            this._requiredBehaviorSubject.asObservable(),
            this._formGroupNameBehaviorSubject.asObservable()
          ]).pipe(
            map(([item]) => (item))
          );
        })
      ),
      errorMessageObservable: this.translationService.translate('autocomplete.required'),
      identity: 'autocompleteRequired'
    }
  );

}
