import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

export interface StandaloneValidatorOptions<T = any> {
  updateObservable?: Observable<T>;
  errorMessageObservable?: Observable<string>;
  errorMessage?: string;
  identityObserver?: Observable<string>;
  identity?: string;
}

export class StandaloneValidator<T = any> implements Validators {

  private updateSubscription: Subscription;
  private idSubscription: Subscription;
  private errMsgSubscription: Subscription;

  host = {
    formControl: new FormControl<T>(null)
  };

  errorMessage: string;
  identity: string;

  constructor(validateFn: (arg?: T) => boolean, options?: StandaloneValidatorOptions<T>) {

    this.errorMessage = options?.errorMessage || 'Error';
    this.identity = options?.identity || 'pseudoValidator';

    this.host.formControl.addValidators(control => {
      const value = control?.value as T;
      const isValid = validateFn(value);

      const error: ValidationErrors = {};
      error[this.identity] = this.errorMessage;

      return isValid ? null : error;
    });

    // subscribe to identity and error message observer first so that their value can depends on the updated value
    if (options?.identityObserver) {
      this.idSubscription = options.identityObserver.subscribe(id => this.identity = id);
    }

    if (options?.errorMessageObservable) {
      this.errMsgSubscription = options.errorMessageObservable.subscribe(msg => this.errorMessage = msg);
    }

    if (options?.updateObservable) {
      this.updateSubscription = options.updateObservable.subscribe(_ => this.updateValueAndValidity(_));
    }

  }

  unsubscribe() {
    this.updateSubscription?.unsubscribe();
    this.idSubscription?.unsubscribe();
    this.errMsgSubscription?.unsubscribe();
  }

  updateValueAndValidity(value?: T) {
    this.host.formControl.setValue(value);
  }

}
