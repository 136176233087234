
<ng-container *ngIf="(dataSource$ | async) as dataSource">

  <app-input
    [ngClass]="['app-autocomplete-input', (required ? 'app-input-required' : ''), dataSource.options.simulateDropdown ? 'simulate-dropdown' : '', (open$ | async) ? 'open' : 'closed']"
    [placeholder]="placeholder"
    [labelless]="labelless"
    [label]="label || ((dataSource.options.fallbackLabelTranslationKey || 'noFallback.translationKey') | cxTranslate)"
    [(value)]="__inputDebouncer.bindableValue"
    [disabled]="disabled"
    [narrow]="narrow"
    iconType="custom"
    [customIconType]="dataSource.options.simulateDropdown ? 'CARET_DOWN' : ((__inputDebouncer.value.length > 0) ? 'CLOSE' : '')"
    (custom)="dataSource.options.simulateDropdown ? toggleMenu() : deleteText()"
    (info)="clickInfo($event)"
    [showInfoIcon]="showInfoIcon"
    (focus)="onFocus($event)"
    [readonly]="dataSource.options.simulateDropdown"
    (inputText)="inputTextHandler($event)"
    removeInnerIconFromTabOrder
    cancelBrowserAutocomplete
    [triggerFocus]="inputFocusTrigger"
    triggerSelector="input"
    triggerDelay="200"
    [aco]="aco$ | async"
  ></app-input>

  <app-sliding-content [expanded]="open$ | async" keepRendering>

    <div class="app-autocomplete-items-container" [attr.role]="listbox" [attr.id]="listboxId">
      <ng-container *ngIf="dataSource.visibleItems$ | async as visibleItems">


        @for (item of visibleItems; track item.value; let i = $index) {

          <div
            [ngClass]="['app-autocomplete-item', item.selected ? 'item-selected' : '', item.pending ? 'item-pending' : '', item.focused ? 'item-focused' : '']"
            (click)="clickItem(item, $event)"
            [attr.id]="listboxId + '_' + item.value"
            [attr.role]="'option'"
            [attr.aria-selected]="!!(item.selected) + ''"
          >{{ item.label }}
            <ng-container *ngIf="celluloidData$ | async as celluloidData">
              <div class="item-celluloid">
                <ng-container
                  [ngComponentOutlet]="celluloidData.component"
                  [ngComponentOutletInjector]="getInjectorForThisItem(item)"
                  [ngComponentOutletContent]="{'text': 'hello'}"
                ></ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="item.focused"><div class="item-celluloid item-focused"></div></ng-container>
            <ng-container *ngIf="item.selected"><div class="item-celluloid item-selected"></div></ng-container>
            <ng-container *ngIf="item.pending"><div class="item-celluloid item-pending"></div></ng-container>
          </div>

        }

        <ng-container *ngIf="visibleItems.length === 0">
          <div class="app-autocomplete-item no-visible-items-exist">
            {{ (dataSource.options.noVisibleItemsTranslationKey || 'autocomplete.noVisibleItems') | cxTranslate }}
          </div>
        </ng-container>

      </ng-container>
    </div>

  </app-sliding-content>

</ng-container>
